
@import '../../styles/variables';

.floatingactionbutton-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 950;

    .floatingactionbutton-sociallink {
        margin-left: 8px;
        color: #fff;

        .MuiSvgIcon-root {
            font-size: 32px !important;
        }

        &.icon-whatsapp {
            background-color: #25d366;
        }

        &.icon-telegram {
            background-color: #229ED9;
        }

        &.icon-instagram {
            background-color: #dd2a7b;
        }

        &.icon-facebook {
            background-color: #4267B2;
        }

        &.icon-twitter {
            background-color: #1DA1F2;
        }

        &.icon-linkedin {
            background-color: #0077b5;
        }

        &.icon-youtube {
            background-color: #FF0000;
        }
    }
}
