
@import '../../styles/variables';

.agreements-section {
    .agreements-list {

        .agreements-item {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 60%;
                height: auto;
                max-height: 100%;
                object-fit: contain;
                // filter: grayscale(100%);
                transition: 0.2s;
                cursor: pointer;

                &:hover {
                    width: 80%;
                    // filter: unset;
                    transition: 0.2s;
                }
            }
        }
    }
}


@media screen and (max-width: $screen-size-md) {
    .agreements-section {
        .agreements-list {
            .MuiImageList-root {
                grid-template-columns: repeat(4, 1fr) !important;

                .MuiImageListItem-root {
                    height: 120px !important;
                }
            }
        }
    }    
}


@media screen and (max-width: $screen-size-sm) {
    .agreements-section {
        .agreements-list {
            .MuiImageList-root {
                grid-template-columns: repeat(2, 1fr) !important;

                .MuiImageListItem-root {
                    height: 100px !important;
                }
            }
        }
    }    
}
