
@import '../../styles/variables';

.service-section {
    .service-list {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        .service-card {
            min-height: 320px;
            margin: 12px 0px;
            overflow: hidden;
            background-color: $content-services-card-background;
            display: flex;

            img {
                height: 100%;
                width: 400px;
                object-fit: cover;
                object-position: center;
                padding: 12px;
                box-sizing: border-box;
            }

            .service-text {
                height: 100%;
                padding: 32px;

                .name {
                    font-size: 20px;
                    width: 100%;
                    color: $content-services-card-name-color;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 20px 0px;
                    box-sizing: border-box;
                    font-weight: 600;
                }
    
                .description {
                    width: 100%;
                    color: $content-services-card-description-color;
                    box-sizing: border-box;
                    z-index: 98;
                }
            }
        }
    }
}


@media screen and (max-width: $screen-size-md) {
    .service-section {
        .service-list {
            .service-card {
                min-height: unset;
                flex-direction: column;
    
                img {
                    height: 400px;
                    width: 100%;
                }
    
                .service-text {
                    height: unset;
                    padding: 20px;
                    padding-bottom: 40px;

                    .name {
                        padding-top: 12px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $screen-size-sm) {
    .service-section {
        .service-list {
            .service-card {
                img {
                    height: 320px;
                    object-position: top left;
                    padding: 0px;
                }
            }
        }
    }
}