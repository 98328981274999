
@import '../../styles/variables';

.contact-section {
    .MuiInputLabel-root.Mui-focused {
        color: $content-contact-textfield-color;
    }

    .MuiFilledInput-root::after {
        border-color: $content-contact-textfield-color;
    }

    .button-send-form {
        padding-left: 80px !important;
        padding-right: 80px !important;
        margin: 0 auto;
        display: flex;
        color: $content-contact-button-color !important;
        background-color: $content-contact-button-background !important;
    }

    .card-contact {
        margin-top: 20px;
        cursor: pointer;

        .icon-contact {
            background-color: $content-contact-icon-background !important;
            color: $content-contact-icon-color !important;
            font-size: 24px;
            border-radius: 12px;
            padding: 8px;
        }
    }
}


@media screen and (max-width: $screen-size-md) {
    .contact-section {
        .card-contact:first-child {
            margin-top: 40px;
        }
    }
}