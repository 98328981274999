
@import '../../styles/variables';

.about-section {
    iframe {
        width: 100%;
        height: 500px;
        border: none;
        border-radius: 2px;
        margin-top: 32px;
    }

    img.image-frontal {
        width: 100%;
        height: 500px;
        border: none;
        border-radius: 2px;
        margin-top: 32px;
        object-fit: cover;
        padding-right: 12px;
        box-sizing: border-box;
    }
}


@media screen and (max-width: $screen-size-md) {
    .about-section {
        img.image-frontal {
            padding-right: 0px;
        }
    }
}