
@import '../../styles/variables';

.navbar-main {
    background-color: $navbar-background !important;
    height: $navbar-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 40px;
    border-radius: 0px !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    overflow-y: hidden;
    position: sticky;
    top: 0px;
    z-index: 940;
    transition: 0.2s !important;
    cursor: pointer !important;

    &.scrolling-up {
        transition: 0.2s;
        top: $header-height;
    }

    .trigger-navbar-close {
        display: none;
        position: absolute;
        top: 12px;
        right: 12px;
    }

    .navbar-item {
        height: 100%;
        display: flex;
        align-items: center;
        color: $navbar-color;
        text-transform: uppercase;
        padding: 0px 16px;
        text-decoration: none;
        font-size: 13px !important;
        position: relative;
        transition: 0.2s;

        &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 10%;
            width: 80%;
            height: 4px;
            background-color: $navbar-color-hover;
            opacity: 0;
            border-radius: 2px;
            transition: 0.2s;
        }

        &.actived, &:hover {
            color: $navbar-color-hover !important;

            &::before {
                opacity: 1;
            }
        }
    }
}

.backdrop-navbar {
    display: none !important;
    z-index: -1;
}

@media screen and (max-width: $screen-size-md) {
    .navbar-main {
        position: fixed;
        width: calc(100% - (20px * 2)) !important;
        height: calc(100% - (20px * 2)) !important;
        top: 20px !important;
        left: 20px;
        border-radius: 4px !important;
        background-color: #fff !important;
        flex-direction: column !important;
        padding: 20px;
        padding-top: 72px;
        align-items: flex-start;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
        z-index: 990;

        &.opened {
            transition: 0.2s;
            opacity: 1;
            visibility: visible;
        }

        .trigger-navbar-close {
            display: flex;
        }

        .navbar-item, .navbar-item.actived {
            height: 40px;
            width: 100%;
            color: $navbar-color-responsive !important;
            box-sizing: border-box;
            font-size: 15px !important;
            border-radius: 2px;
            padding: 0px 12px;
            margin-top: 4px;
            transition: 0.2s;
            
            &::before {
                right: -1px;
                left: unset;
                top: 10%;
                bottom: unset;
                width: 4px;
                height: 80%;
                background-color: $navbar-background;
                opacity: 0;
            }
    
            &:hover {
                background-color: $header-icon-background !important;
                color: $navbar-color-responsive !important;
                transition: 0.2s;

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    .backdrop-navbar.opened {
        display: flex !important;
        z-index: 980;
    }
}