

// Responsive Dimensions
$screen-size-xs: 0px;
$screen-size-sm: 600px;
$screen-size-md: 900px;
$screen-size-lg: 1200px;
$screen-size-xl: 1536px;


// Colors
$screen-background-color: #f5f5f5;

$color-grey-1: #eeeeee;
$color-grey-2: #e0e0e0;



// Header
$header-height: 64px;
$header-color: #080806;
$header-icon-background: #e8f5e9;
$header-icon-color: #1b5e20;


// Navbar
$navbar-background: #2e7d32;
$navbar-color: #c8e6c9;
$navbar-color-hover: #fff;
$navbar-color-responsive: #080806;
$navbar-height: 48px;


// Content
$content-subtitle-color: #969696;
$content-title-color: #1b5e20;

$content-start-text-background: rgba(255, 255, 255, 0.5);

$content-services-card-background: #e0e0e0;
$content-services-card-name-color: #080806;
$content-services-card-description-color: #000;

$content-contact-textfield-color: #2e7d32;
$content-contact-button-background: #2e7d32;
$content-contact-button-color: #fff;
$content-contact-icon-background: #e8f5e9;
$content-contact-icon-color: #1b5e20;


// Footer
$footer-background: #ffffff;
$footer-color: #080806;