
@import '../../styles/variables';

.footer-main {
    background-color: $footer-background;
    color: $footer-color;
    padding: 20px 40px;

    a {
        text-decoration: none;
    }

    .grid-sociallinks {
        padding: 0px 20px;
        
        .iconbutton-sociallink {
            margin: 4px;
    
            &.icon-whatsapp {
                color: #25d366;
            }
    
            &.icon-telegram {
                color: #229ED9;
            }
    
            &.icon-instagram {
                color: #dd2a7b;
            }
    
            &.icon-facebook {
                color: #4267B2;
            }
    
            &.icon-twitter {
                color: #1DA1F2;
            }
    
            &.icon-linkedin {
                color: #0077b5;
            }
    
            &.icon-youtube {
                color: #FF0000;
            }
        }
    }

    .text-copyright {
        margin-left: 36px;
    }
}


@media screen and (max-width: $screen-size-md) {
    .footer-main {
        padding: 20px 20px;
        text-align: center;

        .grid-sociallinks {
            margin-top: 32px;

            .iconbutton-sociallink {

            }
        }

        .text-copyright {
            margin-left: 0px;
            display: block;
            margin-top: 8px;
        }
    }
}