
@import './variables';
@import './fonts';


* {
    font-family: 'Inter', sans-serif !important;
}

body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
    background: $screen-background-color !important;
}


img {
    -webkit-user-drag: none !important;
    -khtml-user-drag: none !important;
    -moz-user-drag: none !important;
    -o-user-drag: none !important;
    user-drag: none !important;
}



// General
.cursor-pointer {
    cursor: pointer !important;
}

.textalign-center {
    text-align: center !important;
}

.textalign-right {
    text-align: right !important;
}


.fontsize-8 { font-size: 8px !important; }
.fontsize-10 { font-size: 10px !important; }
.fontsize-12 { font-size: 12px !important; }
.fontsize-14 { font-size: 14px !important; }
.fontsize-16 { font-size: 16px !important; }
.fontsize-18 { font-size: 18px !important; }
.fontsize-20 { font-size: 20px !important; }
.fontsize-22 { font-size: 22px !important; }
.fontsize-24 { font-size: 24px !important; }
.fontsize-26 { font-size: 26px !important; }
.fontsize-28 { font-size: 28px !important; }
.fontsize-30 { font-size: 30px !important; }
.fontsize-32 { font-size: 32px !important; }

.fontweight-600 {
    font-weight: 600 !important;
}


// Padding
.paddingTop-none { padding-top: 0px !important; }
.paddingBottom-none { padding-bottom: 0px !important; }
.paddingRight-none { padding-right: 0px !important; }
.paddingLeft-none { padding-left: 0px !important; }

.paddingY-none { 
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.paddingX-none { 
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.paddingY-8 { 
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}


// Animations
@keyframes scale_in {
    0% { transform: scale(0); }
    70% { transform: scale(1); }
}
