
@import '../../styles/variables';

.content-main {
    .grid-section {
        padding: 10px 20px;
        margin: 30px 30px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 140px;
            width: 2px;
            background-color: $navbar-background;
        }

        .subtitle {
            display: block;
            width: 100%;
            text-transform: uppercase;
            font-size: 15px;
            color: $content-subtitle-color;
        }

        .title {
            display: block;
            width: 100%;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 22px;
            color: $content-title-color;
        }
    }
}


@media screen and (max-width: $screen-size-md) {
    .content-main {
        .grid-section {
            padding: 10px 20px;
            margin: 30px 0px;
        }
    }
}